import firebase from "firebase/app";
import "@firebase/firestore";
import "@firebase/database";
import "@firebase/auth";

// Your web app's Firebase configuration

const apiKey = "AIzaSyBNksvGvWp1z0_em9JDxNgWsuRvLXaRO-g";
const authDomain = "yoahnn-56d46.firebaseapp.com";
const projectId = "yoahnn-56d46";
const storageBucket = "yoahnn-56d46.appspot.com";
const messagingSenderId = "723028447015";
const appId = "1:723028447015:web:cd968e29f1fe2f4c96e302";
const measurementId = "G-B5GW9QN5SJ";

const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

(async () => {
  const level = firebase.auth.Auth.Persistence.LOCAL;
  await firebase.auth().setPersistence(level);
})();

// Initialize Firebase
export default firebaseApp;
