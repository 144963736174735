const mutations = {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateField(state: { [x: string]: unknown }, { field, val }: unknown) {
    if (field) state[field.toString()] = val;
  },

  toggleDummy(state: { showDummy: boolean; showMjd: boolean }): void {
    if (state.showDummy) {
      state.showDummy = false;
      state.showMjd = true;
    } else {
      state.showMjd = false;
      state.showDummy = true;
    }
  },
  writeBubble(
    state: { bubbleText: string; isBubbleUpdated: boolean },
    text: string
  ): void {
    state.bubbleText = text;
    state.isBubbleUpdated = true;
  },

  markBubbleRead(state: { isBubbleUpdated: boolean }): void {
    state.isBubbleUpdated = false;
  },

  setUserProfile(
    state: { userProfile: UnknownObj; isLoggedIn: boolean },
    userProfile: UnknownObj
  ): void {
    state.userProfile = userProfile;
    if (state.userProfile?.name) {
      state.isLoggedIn = true;
    } else {
      state.isLoggedIn = false;
    }
  },

  storeActus(state: { actus: UnknownObj[] }, actus: UnknownObj[]): void {
    state.actus = [...actus];
  },
  storePosts(state: { posts: UnknownObj[] }, posts: UnknownObj[]): void {
    state.posts = [...posts];
  },
  getSpecificActu(state: { specificActu: UnknownObj }, actu: UnknownObj): void {
    state.specificActu = actu;
  },
  getSpecificBlogPost(
    state: { specificBlogPost: UnknownObj },
    post: UnknownObj
  ): void {
    state.specificBlogPost = post;
  },
  changeFontSize(state: { fontSize: number }, fontSize: number): void {
    state.fontSize = fontSize;
  },
};

export default mutations;
