





































































































































































































import Vue from "vue";
import store from "@/store";
import Menu from "@/components/atoms/Menu.vue";
import Bubble from "@/components/atoms/Bubble.vue";
import firebase from "@/firebase";
import AdminNewActu from "@/components/molecules/AdminNewActu.vue";
import AdminNewBlogPost from "@/components/molecules/AdminNewBlogPost.vue";
import Footer from "@/components/atoms/Footer.vue";

const db = firebase.firestore();

export default Vue.extend({
  name: "App",
  components: { Menu, Bubble, AdminNewActu, AdminNewBlogPost, Footer },

  data: () => ({
    showUserOptions: false,
    adjustedFontSize: 50,
    showNewActu: false,
    showNewBlogPost: false,
    debounceTimer: 0,
    unsubProfile: function () {
      return;
    },
    updatedProfile: {},
    isBubbleMaximized: true,
  }),

  computed: {
    userAdjustedFontSize(): number {
      return (this.adjustedFontSize * 2) / 100;
    },
    fontSize(): number {
      return store.state.fontSize;
    },
    showDummy(): boolean {
      return store.state.showDummy;
    },
    showMjd(): boolean {
      return store.state.showMjd;
    },
    bubbleText(): string {
      return store.state.bubbleText || "";
    },
    userProfile() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return store.state.userProfile || ({} as any);
    },
    isLoggedIn(): boolean {
      return store.state.isLoggedIn;
    },
    isBubbleUpdated(): boolean {
      return store.state.isBubbleUpdated;
    },
    isHome(): boolean {
      return this.$route.name === "Home";
    },
  },
  methods: {
    commitNewFontSize(): void {
      store.commit("changeFontSize", this.userAdjustedFontSize);
    },
    reinitializeFontSize(): void {
      store.commit("changeFontSize", 1);
      this.adjustedFontSize = 50;
    },
    goHome(): void {
      this.$router.push("/");
    },
    minimizeBubble(): void {
      this.isBubbleMaximized = false;
    },
    maximizeBubble(): void {
      this.isBubbleMaximized = true;
      store.commit("markBubbleRead");
    },
    toggleDummy(): void {
      store.commit("toggleDummy");
      // TODO: ask Y what standard text he wants here
      if (this.showDummy) {
        store.commit("writeBubble", "Je suis Yohann...");
      } else {
        store.commit("writeBubble", "Je suis Marie-Jo !");
      }
    },
    onResize(): void {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        const windowWidth = window.innerWidth;
        store.commit("updateField", {
          field: "isMobile",
          val: windowWidth <= 600,
        });
        store.commit("updateField", {
          field: "isTablet",
          val: windowWidth <= 1200 && windowWidth > 600,
        });
      }, 250);
    },
    fetchProfile() {
      this.unsubProfile = db
        .collection("users")
        .doc(this.userProfile.id)
        .onSnapshot((doc) => {
          this.updatedProfile = { ...doc.data() };
          store.commit("updateProfile", this.updatedProfile);
        });
    },
    logout(): void {
      store.dispatch("LOG_OUT");
    },
    validateUser() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          store.dispatch("GET_PROFILE").then(() => {
            setTimeout(() => {
              this.validateLogin();
            }, 250);
          });
        }
      });
    },
    validateLogin(): void {
      store.commit("updateField", { field: "isLoggedIn", val: true });
    },
    updateBubbleText(text: string): void {
      store.commit("writeBubble", text);
    },
  },
  created() {
    this.unsubProfile = function () {
      return null;
    };
    window.addEventListener("resize", this.onResize);
    this.$nextTick(() => {
      this.onResize();
    });
  },
  beforeDestroy() {
    if (this.unsubProfile) {
      this.unsubProfile();
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  mounted() {
    setTimeout(() => {
      this.validateUser();
    }, 250);
  },
});
