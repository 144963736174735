import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

// check how to add OG tags from here

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/bio",
    name: "Bio",
    component: () => import("../views/Bio.vue"),
  },
  {
    path: "/actu",
    name: "Actu",
    component: () => import("../views/Actu.vue"),
  },
  {
    path: "/radio",
    name: "Radio",
    component: () => import("../views/Radio.vue"),
  },
  {
    path: "/ecole",
    name: "Ecole",
    component: () => import("../views/Ecole.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/Blog.vue"),
  },
  {
    path: "/modele",
    name: "Modele",
    component: () => import("../views/Modele.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/actu/:id",
    component: () => import("../views/SelectedActu.vue"),
  },
  {
    path: "/blog/:id",
    component: () => import("../views/SelectedBlog.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
