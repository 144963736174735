




import Vue from "vue";
import spray from "@/assets/animations/spray";

export default Vue.extend({
  name: "",
  components: {},
  mounted() {
    spray("spray");
  },
  data() {
    return {};
  },
  methods: {},
});
