

























































































































import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  name: "Menu",
  components: {},
  computed: {
    showDummy(): boolean {
      return store.state.showDummy;
    },
    showMjd(): boolean {
      return store.state.showMjd;
    },
    menuItems() {
      return store.state.menuItems;
    },
  },
  data() {
    return {
      showMenu: false,
      group: null,
    };
  },
  methods: {
    makeYohannSpeak(text: string) {
      store.commit("writeBubble", text);
    },
    goToPage(page: { link: string; bubbleText: string }): void {
      if (this.$route.path !== page.link) {
        this.$router.push({ path: page.link });
        this.makeYohannSpeak(page.bubbleText);
      } else {
        this.showMenu = !this.showMenu;
      }
    },
  },
});
