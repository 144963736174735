const state = {
  fontSize: 1,
  isMobile: false,
  isTablet: false,
  isLoggedIn: false,
  showDummy: true,
  showMjd: false,
  showBubble: true,
  bubbleText: "",
  isBubbleUpdated: false,
  userProfile: {},
  actus: [],
  specificActu: {},
  specificBlogPost: {},
  posts: [],
  contactRequests: [],
  menuItems: [
    {
      name: "Bio",
      link: "/bio",
      icon: "mdi-face-recognition",
      bubbleText: "La Bretagne ça vous gagne. Ou pas.",
      mjdText: "",
    },
    {
      name: "Actu",
      link: "/actu",
      icon: "mdi-newspaper",
      bubbleText: "Alors en ce moment, je suis là.",
      mjdText: "",
    },
    {
      name: "Blog",
      link: "/blog",
      icon: "mdi-fountain-pen-tip",
      bubbleText: "J'écris aussi, parce que bon, j'en faisais pas assez.",
      mjdText: "",
    },

    {
      name: "Radio",
      link: "/radio",
      icon: "mdi-radio",
      bubbleText: "Concentrez-vous sur ma voix...",
      mjdText: "",
    },
    {
      name: "Ecole",
      link: "/ecole",
      icon: "mdi-school",
      bubbleText: "Je peux aussi t'apprendre des choses...",
      mjdText: "",
    },

    {
      name: "Modèle",
      link: "/modele",
      icon: "mdi-penguin",
      bubbleText: "Là on m'entend pas...",
      mjdText: "",
    },
    {
      name: "HOME",
      link: "/",
      icon: "mdi-home",
      bubbleText: "Texte d'accueil de base",
      mjdText: "",
    },
  ],
};

export default state;
