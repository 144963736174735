
































































































import Vue from "vue";
import store from "@/store";
import Spray from "@/components/atoms/Spray.vue";

export default Vue.extend({
  name: "Home",

  components: { Spray },
  data: () => ({
    welcomeBubbleYohann: "En trois mots: la vie, la mort, le sexe.",
    welcomeBubbleMjd: "Allez, on se met en rang par six maintenant !",
  }),

  computed: {
    showDummy(): boolean {
      return store.state.showDummy;
    },
    showMjd(): boolean {
      return store.state.showMjd;
    },
    isMobile(): boolean {
      return store.state.isMobile;
    },
  },
  methods: {
    writeBubble(): void {
      if (this.showDummy) {
        store.commit("writeBubble", this.welcomeBubbleYohann);
      } else if (this.showMjd) {
        store.commit("writeBubble", this.welcomeBubbleMjd);
      }
    },
    goToActu(): void {
      this.$router.push("/actu");
    },
    goToBio(): void {
      this.$router.push("/bio");
    },
  },
  updated() {
    this.writeBubble();
  },
  created() {
    this.writeBubble();
  },
  beforeDestroy() {
    store.commit("writeBubble", "");
  },
  mounted() {
    window.scrollTo(0, 0);
  },
});
