




















































































































































































































































import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  name: "Footer",
  components: {},
  computed: {
    showDummy(): boolean {
      return store.state.showDummy;
    },
    showMjd(): boolean {
      return store.state.showMjd;
    },
    areFieldsEmpty(): boolean {
      return !this.contactName && !this.contactEmail && !this.contactMessage;
    },
    isNameEmpty(): boolean {
      return !this.contactName;
    },
    isEmailEmpty(): boolean {
      return !this.contactEmail;
    },
    isMessageEmpty(): boolean {
      return !this.contactMessage;
    },
    isMobile(): boolean {
      return store.state.isMobile;
    },
  },
  data: () => ({
    icons: [
      { link: "https://www.facebook.com/laveantyohann/", icon: "mdi-facebook" },
      {
        link: "https://www.instagram.com/yohannlaveant/?hl=fr",
        icon: "mdi-instagram",
      },
    ],
    rules: {
      required: (value: string) => !!value || "Required.",
      counterName: (value: string | string[]) =>
        value?.length <= 30 || "Max 30 caractères",
      counterMessage: (value: string | string[]) =>
        value?.length <= 256 || "Max 256 caractères",
      email: (value: string) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail invalide.";
      },
    },
    isFormValid: false,
    showContactForm: false,
    contactName: "",
    contactEmail: "",
    contactMessage: "",
    isAdmin: false,
    errorMessage: "",
    successMessage: "",
  }),
  methods: {
    clearFields(): void {
      this.contactName = "";
      this.contactEmail = "";
      this.contactMessage = "";
      this.successMessage = "";
      this.errorMessage = "";
    },
    submitContactRequest(): void {
      this.errorMessage = "";
      if (!this.isFormValid || this.areFieldsEmpty) {
        this.errorMessage =
          "Vous devez compléter les champs pour envoyer la demande de contact.";
      }
      if (
        !this.isEmailEmpty &&
        !this.isMessageEmpty &&
        !this.isNameEmpty &&
        this.isFormValid &&
        !this.isAdmin
      ) {
        //FB STUFF HERE
        const payload = {
          name: this.contactName,
          email: this.contactEmail,
          message: this.contactMessage,
        };

        store.dispatch("SEND_CONTACT_REQUEST", payload).then(() => {
          this.successMessage = "Message envoyé! Merci :)";
          setTimeout(() => {
            this.clearFields();
            this.showContactForm = !this.showContactForm;
          }, 1000);
        });
      } else {
        return;
      }
    },
  },
});
