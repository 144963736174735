import firebase from "@/firebase";
import { v4 as uuidv4 } from "uuid";

const db = firebase.firestore();

const actions = {
  SIGN_IN({ commit }: StoreProp, payload: UnknownObj): UnknownObj {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(payload.email || "", payload.password || "")
        .then((userCredential) => {
          firebase
            .firestore()
            .collection("users")
            .doc(userCredential.user?.uid)
            .get()
            .then((response) => {
              if (commit) {
                const user = response.data();
                commit("setUserProfile", user);
                resolve(true);
              }
            });
        })
        .catch((err: UnknownObj) => {
          reject(err.message);
        });
    });
  },

  LOG_OUT({ commit }: StoreProp): UnknownObj {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (commit) {
            commit("setUserProfile", {});
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  },

  GET_PROFILE({ commit }: StoreProp): UnknownObj {
    return new Promise((resolve, reject) => {
      const currentUser = firebase.auth().currentUser;

      if (!currentUser) return;

      db.collection("users")
        .doc(currentUser?.uid)
        .get()
        .then((documentData) => {
          if (commit) {
            commit("setUserProfile", documentData.data());
            return documentData;
          }
        })
        .then((documentData) => {
          resolve(documentData?.data());
        })
        .catch((err: UnknownObj) => {
          console.error(err);
          reject(err);
        });
    });
  },

  SAVE_ACTU({ commit }: StoreProp, payload: UnknownObj): UnknownObj {
    const postId = uuidv4();
    const now = new Date().toLocaleDateString();
    payload.createdAt = now;
    payload.id = postId;
    return new Promise((resolve, reject) => {
      db.collection("actu")
        .doc(postId)
        .set(payload)
        .then(() => {
          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  FETCH_ACTUS({ commit }: StoreProp): UnknownObj {
    return new Promise((resolve, reject) => {
      db.collection("actu")
        .orderBy("createdAt", "asc")
        .get()
        .then((querySnapshot) => {
          const actus = [] as UnknownObj;
          querySnapshot.forEach((doc) => {
            actus.push(doc.data());
          });
          if (commit) {
            commit("storeActus", actus);
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  GET_SPECIFIC_ACTU({ commit }: StoreProp, id: string): UnknownObj {
    return new Promise((resolve, reject) => {
      db.collection("actu")
        .doc(id)
        .get()
        .then((doc) => {
          const actu = doc.data();
          if (commit) {
            commit("getSpecificActu", actu);
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  GET_SPECIFIC_BLOG_POST({ commit }: StoreProp, id: string): UnknownObj {
    return new Promise((resolve, reject) => {
      db.collection("posts")
        .doc(id)
        .get()
        .then((doc) => {
          const post = doc.data();
          if (commit) {
            commit("getSpecificBlogPost", post);
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  FETCH_BLOG_POSTS({ commit }: StoreProp): UnknownObj {
    return new Promise((resolve, reject) => {
      db.collection("posts")
        .orderBy("createdAt", "asc")
        .get()
        .then((querySnapshot) => {
          const posts = [] as UnknownObj;
          querySnapshot.forEach((doc) => {
            posts.push(doc.data());
          });
          if (commit) {
            commit("storePosts", posts);
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  SAVE_BLOG_POST({ commit }: StoreProp, payload: UnknownObj): UnknownObj {
    const postId = uuidv4();
    const now = new Date().toLocaleDateString();
    payload.createdAt = now;
    payload.id = postId;
    return new Promise((resolve, reject) => {
      db.collection("posts")
        .doc(postId)
        .set(payload)
        .then(() => {
          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  DELETE_ACTU({ commit }: StoreProp, payload: UnknownObj): UnknownObj {
    const { id, imageDataName } = payload;
    return new Promise((resolve, reject) => {
      db.collection("actu")
        .doc(id)
        .delete()
        .then(() => {
          if (imageDataName) {
            firebase.storage().ref(imageDataName).delete();
          }

          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  DELETE_BLOG_POST({ commit }: StoreProp, payload: UnknownObj): UnknownObj {
    const { id, imageDataName } = payload;
    return new Promise((resolve, reject) => {
      db.collection("posts")
        .doc(id)
        .delete()
        .then(() => {
          if (imageDataName) {
            firebase.storage().ref(imageDataName).delete();
          }

          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  SEND_CONTACT_REQUEST({ commit }: StoreProp, payload: UnknownObj): UnknownObj {
    const id = uuidv4();
    payload.id = id;
    payload.date = new Date().toLocaleDateString();

    return new Promise((resolve, reject) => {
      db.collection("contacts")
        .doc(payload.id)
        .set(payload)
        .then(() => {
          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  DELETE_CONTACT_REQUEST({ commit }: StoreProp, id: string): UnknownObj {
    return new Promise((resolve, reject) => {
      db.collection("contacts")
        .doc(id)
        .delete()
        .then(() => {
          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },

  SEND_RESET_PASSWORD_EMAIL({ commit }: StoreProp, email: string): UnknownObj {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          if (commit) {
            resolve(true);
          }
        })
        .catch((err) => {
          console.error(err.message);
          reject(err);
        });
    });
  },
};

export default actions;
