














































































































































import Vue from "vue";
import store from "@/store";
import firebase from "firebase/app";
import "firebase/storage";

export default Vue.extend({
  name: "AdminNewActu",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActuValid: false,
      actuTitle: "",
      actuText: "",
      actuLink: "",
      actuLinkCaption: "",
      actuPictureCaption: "",
      actuIcon: "",
      yohannThink: "",
      icons: [
        "mdi-newspaper-variant-outline",
        "mdi-calendar-month",
        "mdi-calendar-account",
        "mdi-balloon",
        "mdi-party-popper",
        "mdi-flower-outline",
        "mdi-message-outline",
        "mdi-thought-bubble-outline",
      ],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      imageData: null || ({} as ImageData | Record<string, unknown> | any),
      picture: null,
      uploadValue: 0,
      isPostSent: false,
      rules: [
        (value: string) =>
          (value || "").length <= 80 || "Max 80 caractères, dude.",
      ],
    };
  },
  computed: {
    show(): boolean {
      return this.dialog;
    },
  },
  methods: {
    closeNewActu(): void {
      this.$emit("closeNewActu");
    },
    selectIcon(icon: string) {
      this.actuIcon = icon;
    },
    previewImage(event: { target: { files: ImageData[] } }) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
      // find a way to generate a low version of the image
    },
    onUpload() {
      this.picture = null;
      const storageRef = firebase.storage().ref();

      if (this.imageData.name) {
        const imageRef = storageRef
          .child(`${this.imageData.name}`)
          .put(this.imageData);

        imageRef.on(
          `state_changed`,
          (snapshot) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            imageRef.snapshot.ref.getDownloadURL().then((url) => {
              this.picture = url;
              this.saveActu(this.imageData.name);
            });
          }
        );
      } else {
        this.saveActu("");
      }
    },
    saveActu(imageDataName: string): void {
      this.isPostSent = false;

      const payload = {
        title: this.actuTitle,
        icon: this.actuIcon,
        text: this.actuText,
        link: this.actuLink,
        linkCaption: this.actuLinkCaption,
        picture: this.picture,
        pictureCaption: this.actuPictureCaption,
        date: new Date(),
        imageDataName: imageDataName,
        yohannThink: this.yohannThink,
      };

      if (this.isActuValid) {
        store
          .dispatch("SAVE_ACTU", payload)
          .then(() => {
            this.resetData();
          })
          .finally(() => {
            this.fetchActus();
            this.closeNewActu();
          });
      }
    },
    resetData(): void {
      this.actuTitle = "";
      this.actuText = "";
      this.actuLink = "";
      this.actuLinkCaption = "";
      this.actuPictureCaption = "";
      this.actuIcon = "";
      this.imageData = "";
      this.yohannThink = "";
      this.isPostSent = true;
      this.isActuValid = false;
    },
    fetchActus(): void {
      store.dispatch("FETCH_ACTUS");
    },
  },
});
