






































































































































import Vue from "vue";
import store from "@/store";
import firebase from "firebase/app";
import "firebase/storage";

export default Vue.extend({
  name: "AdminNewBlogPost",
  components: {},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    show(): boolean {
      return this.dialog;
    },
  },
  data() {
    return {
      isblogPostValid: false,
      blogPostTitle: "",
      blogPostText: "",
      blogPostLink: "",
      blogPostLinkCaption: "",
      blogPostPictureCaption: "",
      blogPostIcon: "",
      icons: [
        "mdi-newspaper-variant-outline",
        "mdi-calendar-month",
        "mdi-calendar-account",
        "mdi-balloon",
        "mdi-party-popper",
        "mdi-flower-outline",
        "mdi-message-outline",
        "mdi-thought-bubble-outline",
      ],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      imageData: null || ({} as any),
      picture: null,
      uploadValue: 0,
      isPostSent: false,
    };
  },
  methods: {
    closeNewBlogPost(): void {
      this.$emit("closeNewBlogPost");
    },
    selectIcon(icon: string) {
      this.blogPostIcon = icon;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    previewImage(event: any) {
      this.uploadValue = 0;
      this.picture = null;
      this.imageData = event.target.files[0];
    },
    onUpload() {
      this.picture = null;
      const storageRef = firebase.storage().ref();

      if (this.imageData.name) {
        const imageRef = storageRef
          .child(`${this.imageData.name}`)
          .put(this.imageData);

        imageRef.on(
          `state_changed`,
          (snapshot: { bytesTransferred: number; totalBytes: number }) => {
            this.uploadValue =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error: { message: string }) => {
            console.log(error.message);
          },
          () => {
            this.uploadValue = 100;
            imageRef.snapshot.ref.getDownloadURL().then((url: null) => {
              this.picture = url;
              this.saveblogPost(this.imageData.name);
            });
          }
        );
      } else {
        this.saveblogPost("");
      }
    },
    saveblogPost(imageDataName: string): void {
      this.isPostSent = false;

      const payload = {
        title: this.blogPostTitle,
        icon: this.blogPostIcon,
        text: this.blogPostText,
        link: this.blogPostLink,
        linkCaption: this.blogPostLinkCaption,
        picture: this.picture,
        pictureCaption: this.blogPostPictureCaption,
        date: new Date(),
        imageDataName: imageDataName,
      };

      if (this.isblogPostValid) {
        store
          .dispatch("SAVE_BLOG_POST", payload)
          .then(() => {
            this.resetData();
          })
          .finally(() => {
            this.fetchPosts();
            this.closeNewBlogPost();
          });
      }
    },
    resetData(): void {
      this.blogPostTitle = "";
      this.blogPostText = "";
      this.blogPostLink = "";
      this.blogPostLinkCaption = "";
      this.blogPostPictureCaption = "";
      this.blogPostIcon = "";
      this.imageData = "";
      this.isPostSent = true;
      this.isblogPostValid = false;
    },
    fetchPosts(): void {
      store.dispatch("FETCH_BLOG_POSTS");
    },
  },
});
