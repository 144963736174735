


























import Vue from "vue";
import store from "@/store";

export default Vue.extend({
  name: "Bubble",
  components: {},
  computed: {
    isLoggedIn(): boolean {
      return store.state.isLoggedIn;
    },
    bubble(): HTMLElement {
      return this.$refs.bubble as HTMLElement;
    },
    isMobile(): boolean {
      return store.state.isMobile;
    },
    isTablet(): boolean {
      return store.state.isTablet;
    },
    isDesktop(): boolean {
      return !this.isMobile && !this.isTablet;
    },
  },
  data() {
    return {
      positions: {
        clientX: 0,
        clientY: 0,
        movementX: 0,
        movementY: 0,
      },
      isHint: false,
    };
  },
  methods: {
    showHint(): void {
      this.isHint = true;
    },
    closeHint(): void {
      this.isHint = false;
    },
    dragMouseDown: function (event: {
      preventDefault: () => void;
      clientX: 0;
      clientY: 0;
    }) {
      event.preventDefault();
      // get the mouse cursor position at startup:
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.closeDragElement;
    },

    elementDrag: function (event: MouseEvent) {
      event.preventDefault();
      this.positions.movementX =
        (this.positions.clientX || 0) - (event.clientX || 0);
      this.positions.movementY =
        (this.positions.clientY || 0) - (event.clientY || 0);
      this.positions.clientX = event.clientX;
      this.positions.clientY = event.clientY;
      // set the element's new position:
      this.bubble.style.top = `${
        this.bubble.offsetTop - this.positions.movementY
      }px`;
      this.bubble.style.left = `${
        this.bubble.offsetLeft - this.positions.movementX
      }px`;
    },
    closeDragElement() {
      document.onmouseup = null;
      document.onmousemove = null;
    },
  },
});
