interface Ctx {
  fillStyle: string;
  beginPath: () => void;
  arc: (
    arg0: number,
    arg1: number,
    arg2: number,
    arg3: number,
    arg4: number
  ) => void;
  fill: () => void;
}

const spray = (canvasId: string): void => {
  let tmpTimer1 = 0;
  let tmpTimer2 = 0;
  let maxIterationsAllowed = 256;
  const MAX_WIDTH = 2000;
  const MAX_HEIGHT = 1000;
  let animationInitialized = false;

  // eslint-disable-next-line @typescript-eslint/ban-types
  let animationKillSwitch: Function = () => null;

  const requestAnimFrame =
    window.requestAnimationFrame ||
    function (callback: FrameRequestCallback) {
      clearTimeout(tmpTimer1);
      tmpTimer1 = setTimeout(callback, 1000 / 60);
    };

  const frameSet = (callback: FrameRequestCallback) => {
    return requestAnimFrame(callback);
  };

  const randomFloat = (min: number, max: number): number => {
    return Math.random() * (max - min) + min;
  };

  class Particle {
    height: number;
    width: number;
    vy: number;
    vx: number;
    gravity: number;
    color: number;
    radius: number;
    // eslint-disable-next-line @typescript-eslint/ban-types
    disp: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    update: Function;
    // eslint-disable-next-line @typescript-eslint/ban-types
    destroy: Function;
    thisRef: UnknownObj;

    constructor(width: number, height: number, vx: number) {
      this.height = height;
      this.width = width;
      this.vy = randomFloat(1.1, 2) * -8 * 1.1;
      this.vx = vx;
      this.gravity = 0.1;
      this.color = 0;
      this.radius = randomFloat(5, 30);

      this.thisRef = [this];

      this.disp = (ctx: Ctx) => {
        ctx.fillStyle = `hsla(${this.color}, 95%, 60%, .6)`;
        ctx.beginPath();
        ctx.arc(this.height, this.width, this.radius, 0, Math.PI * 2);
        ctx.fill();
      };

      this.update = () => {
        this.vy += this.gravity;
        this.width += this.vy * 1.1;
        this.height += this.vx * 1.1;
        this.color += 2;
      };

      this.destroy = () => {
        this.disp = () => null;
        this.update = () => null;
        delete this.thisRef[0];
      };
    }
  }

  const canvas = document.getElementById(canvasId) as HTMLCanvasElement,
    ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

  canvas.width = MAX_WIDTH;
  canvas.height = MAX_HEIGHT;

  const arr: Particle[] = [];

  let updateElements = function () {
    if (!maxIterationsAllowed) {
      return;
    }

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "rgba(255,255,255,.01)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const part = new Particle(
      canvas.height,
      canvas.height,
      Math.random() * 10 - 5
    );
    arr.push(part);

    for (const i in arr) {
      const el = arr[i];
      if (el) {
        el.disp(ctx);
        el.update();
      }
    }

    if (arr.length > 200) {
      arr[0].destroy();
      arr.shift();
    }
  };

  let runAnimation = () => {
    if (!maxIterationsAllowed) {
      animationKillSwitch();
      return;
    }
    maxIterationsAllowed--;
    frameSet(runAnimation);
    updateElements();
  };

  animationKillSwitch = () => {
    updateElements = () => null;
    runAnimation = () => null;
    maxIterationsAllowed = -1;
    clearTimeout(tmpTimer1);
    clearTimeout(tmpTimer2);
    cancelAnimationFrame(frameSet(runAnimation));
  };

  if (!animationInitialized) {
    animationInitialized = true;
    runAnimation();
  }

  clearTimeout(tmpTimer2);
  tmpTimer2 = setTimeout(animationKillSwitch, 4000);
};

export default spray;
